import React from 'react'
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components'
import { Box, SpanStyled } from '../../../Styled/Elements';
import { Email, Location, Phone } from '../../../Styled/AllImages';
import { TextWithIcon } from '../../../Styled/CustomElements';


const Map = () => {
    const mapList = [
        {
            officeName: 'London Office',
            phoneNumber: '+44 7456 399190',
            phoneLink: 'tel:+03164000186',
            email: 'info@torcenetworing.com',
            emailLink: 'mailto:info@torcenetworking.com',
            location: 'Suite 200i Cumberland House, 80 Scrubs Lane, London, England, NW10 6RF',
            map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.152986774389!2d-0.23920537352871837!3d51.52875377181817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487611ccdb65ba73%3A0xe239974dfdcc5e23!2sDeluxe%20Accountants!5e0!3m2!1sen!2s!4v1733252807408!5m2!1sen!2s',
        },
        {
            officeName: 'Tokyo Office',
            phoneNumber: '+818094490897',
            phoneLink: 'tel:+818094490897',
            email: 'info@torcenetworing.com',
            emailLink: 'mailto:info@torcenetworking.com',
            location: 'sisili, Mecidiyeköy /İstanbul, Türkiye',
            map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6015.759904094544!2d28.98718311629249!3d41.07161667822346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6f83672f8bd%3A0x12c9d57e5a4cc0b8!2zTWVjaWRpeWVrw7Z5LCAzNDM4MSDFnmnFn2xpL8Swc3RhbmJ1bCwgVMO8cmtpeWU!5e0!3m2!1sen!2s!4v1714826014885!5m2!1sen!2s',
        }

    ]

    return (
        <MapWapper>
            <Row>
                {
                    mapList.map((list, index) => (
                        <Col key={index} lg={6} md={12} className='my-2'>
                            <Box className='location-card'>
                                <SpanStyled className='sub-heading' color='var(--darkColor)'>{list.officeName}</SpanStyled>
                                <TextWithIcon headingSize='14px' className='contact-text' icon={Phone} title={list.phoneNumber} href={list.phoneLink} />
                                <TextWithIcon headingSize='14px' className='contact-text' icon={Email} title={list.email} href={list.emailLink} />
                                <TextWithIcon icon={Location} title={list.location} href={list.map} />
                                <Box className="w-100">
                                    <iframe src={list.map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </Box>
                            </Box>
                        </Col>
                    ))
                }



            </Row>
        </MapWapper>
    )
}
const MapWapper = styled.div`
.location-card{
    background: white;
    border-radius: 20px;
    box-shadow: 1px 1px 8px #ababab6e;
    padding: 25px 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
iframe{
    border-radius: 20px;
    width: 100%;
    height: 250px;
}
`

export default Map
