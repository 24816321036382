import React from 'react'
import ContactForm from './ContactForm'
import { ContactWapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../Styled/Elements'
import Map from './Map'
import { TextWithIcon } from '../../../Styled/CustomElements'
import { Email, Location, Phone } from '../../../Styled/AllImages'


const Contact = () => {
    const { t } = useTranslation();
    const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6015.759904094544!2d28.98718311629249!3d41.07161667822346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6f83672f8bd%3A0x12c9d57e5a4cc0b8!2zTWVjaWRpeWVrw7Z5LCAzNDM4MSDFnmnFn2xpL8Swc3RhbmJ1bCwgVMO8cmtpeWU!5e0!3m2!1sen!2s!4v1714826014885!5m2!1sen!2s'

    return (
        <ContactWapper id='contact'>
            <Container>
                <Row>
                    <Box className='text-center  my-5'>
                        <HeadingStyled className='themeTextGradient' color='var(--darkColor)'>Contact Us</HeadingStyled>
                        <TextStyled>We're proud to serve you. Explore our network of branches and discover how we can bring our expertise to your doorstep.</TextStyled>
                    </Box>
                </Row>
                <Map />
                <Row className='rows-gap common-space'>
                    <Col lg={6} md={12} className='contact-Us'>
                        <Box className='contect-with-us'>
                            <ContactForm />
                        </Box>
                    </Col>
                    <Col lg={6} md={12} className='my-2'>
                        <Box className='d-flex flex-column gap-3'>
                            <SpanStyled className='sub-heading' color='var(--darkColor)'>Gujranwala Office</SpanStyled>
                            <TextWithIcon headingSize='14px' className='contact-text' icon={Phone} title='03164000186' href="tel:+03164000186" />
                            <TextWithIcon headingSize='14px' className='contact-text' icon={Email} title='info@torcenetworing.com' href='mailto:info@torcenetworking.com' />
                            <TextWithIcon icon={Location} title='sisili, Mecidiyeköy /İstanbul, Türkiye' href={map} />
                            <Box className="w-100">
                                <iframe src={map} allowfullscreen="" loading="lazy" width="100%" height="450px" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>
            {/* <ContactForm /> */}
        </ContactWapper>
    )
}


export default Contact
